<template>
  <ModuleForm
    :module-name="$t('moduleForm.joke')"
    :module="joke"
    :show-header-info="disabled"
  >
    <template #header-buttons>
      <ModuleFormButton
        v-if="disabled"
        icon="edit"
        @click="goToEdit"
      />
      <ModuleFormButton
        v-else
        :title="$t('buttons.save')"
        @click="save"
      />
      <ModuleFormDeleteButton
        v-if="joke.id"
        :record-id="joke.id"
        store-name="joke"
        list-router-path="joke"
      />
      <ModuleFormButton
        icon="close"
        @click="$router.push('/joke')"
      />
    </template>
    <template #left-column>
      <Input
        v-model="joke.title"
        @blur="$v.joke.title.$touch()"
        :error="$v.joke.title.$error"
        id="joke_title"
        :label="$t('joke.title')"
        :placeholder="$t('joke.title_placeholder')"
        :disabled="disabled"
      />
      <div class="form-group">
        <label>
          {{ $t('joke.sites') }}
        </label>
        <MultiSelect
          v-model="joke.expanded.sites"
          :options="sites"
          label="title"
          track-by="id"
          id="joke_sites"
          :disabled="disabled"
        />
      </div>
      <Input
        v-model="joke.author"
        @blur="$v.joke.author.$touch()"
        :error="$v.joke.author.$error"
        id="joke_author"
        :label="$t('joke.author')"
        :disabled="disabled"
      />
      <Datepicker
        v-model="joke.publishedSince"
        id="joke_publishedSince"
        :label="$t('joke.published_since')"
        :placeholder="$t('joke.published_since_placeholder')"
        :show-set-now="!disabled"
        :disabled="disabled"
      />
      <!-- hide till CMS-541 is resolved -->
      <div v-if="false" class="published-dates">
        <Datepicker
          class="published-dates__datepicker"
          v-model="joke.publishedUntil"
          id="joke_publishedUntil"
          :label="$t('joke.published_until')"
          :placeholder="$t('joke.published_until_placeholder')"
          :disabled="disabled"
        />
      </div>
    </template>
    <template #right-column>
      <div class="title-text">
        {{ $t('joke.bodytext') }}
      </div>
      <RichTextEditor
        v-model="joke.bodyText"
        :other-options="richTextEditorConfig"
        id="joke_bodyText"
        rows="10"
        :readonly="disabled"
      />
      <OutlinedCheckbox
        v-model="joke.voting.enabled"
        id="joke_voting_enabled"
        :label="$t('joke.voting')"
        :disabled="disabled"
      />
      <!-- hide till CMS-541 is resolved -->
      <OutlinedCheckbox
        v-if="false"
        v-model="joke.unpublished"
        id="joke_setUnpublished"
        :label="$t('media.set_unpublished')"
        :disabled="disabled"
      />
    </template>
  </ModuleForm>
</template>

<script>
import Input from '@/components/form/inputs/Input'
import Datepicker from '@/components/form/Datepicker'
import RichTextEditor from '@/components/form/RichTextEditor'
import TinyMceConfig from '@/services/tinymce/joke/TinyMceConfig'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import JokeModel from '@/model/JokeModel'
import NotifyService from '@/services/NotifyService'
import MultiSelect from '@/components/form/select/MultiSelect'
import OutlinedCheckbox from '@/components/form/OutlinedCheckbox'
import ModuleFormDeleteButton from '@/components/shared/ModuleFormDeleteButton'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import ModuleForm from '@/components/shared/ModuleForm'

export default {
  name: 'JokeNewView',
  data () {
    return {
      joke: this._.cloneDeep(JokeModel),
      richTextEditorConfig: TinyMceConfig.getConfig()
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  validations: {
    joke: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      },
      author: {
        minLength: minLength(1),
        maxLength: maxLength(250)
      }
    }
  },
  components: {
    ModuleForm,
    ModuleFormDeleteButton,
    ModuleFormButton,
    OutlinedCheckbox,
    Input,
    MultiSelect,
    Datepicker,
    RichTextEditor
  },
  computed: {
    sites () {
      return this.$store.getters['site/enabledSites']()
    }
  },
  methods: {
    prepareRequest (joke) {
      const jokeRequest = this._.cloneDeep(joke)
      jokeRequest.sites = joke.expanded.sites.map(site => site.id)
      if (jokeRequest.expanded) {
        delete jokeRequest.expanded
      }
      return jokeRequest
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        this.$store.dispatch('joke/create', this.prepareRequest(this.joke))
          .then(() => {
            if (this.$store.getters['joke/error'] === null) {
              NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
              this.joke = this.$store.getters['joke/detail']
              this.goToEdit()
            } else {
              NotifyService.setErrorMessage(this.$store.getters['joke/error'])
            }
          })
          .catch(error => console.log(error))
      }
    },
    goToEdit () {
      this.$router.push('/joke/' + this.joke.id + '/edit')
    }
  }
}
</script>

<style scoped lang="scss">
.published-dates {
  display: flex;
  gap: 2%;
  &__datepicker {
    flex: 0 0 49%;
  }
}
.title-text {
  color: #8A96AC;
  font-size: rem(14px);
}
</style>
